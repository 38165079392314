import * as countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
countries.registerLocale(en);
export const displayCountry = (countryCode, isSmallBusiness = false) => {
    if (countryCode === "INT") {
        return "International/Unknown";
    }
    if (countryCode === "GB") {
        return "UK";
    }
    if (countryCode === "US") {
        return "USA";
    }
    if (countryCode === "AE") {
        return "UAE";
    }
    if (countryCode === "EU") {
        if (isSmallBusiness) {
            return "Europe";
        }
        return "Europe / Unknown";
    }
    if (countryCode === "NEU") {
        if (isSmallBusiness) {
            return "Non Europe";
        }
        return "Non Europe / Unknown";
    }
    if (countryCode === "USC") {
        return "US / Canada";
    }
    if (countryCode === "APAC") {
        return "APAC";
    }
    if (countryCode === "ROW") {
        return "ROW";
    }
    return countries.getName(countryCode, "en");
};
