import React, { useEffect, useState } from "react";
import { uuid } from "short-uuid";
import Button from "@Components/Button";
import Form, { InputType } from "@Components/Form";
import Dropdown from "@Components/Form/Dropdown";
import FormInput from "@Components/Form/Input";
import RadioGroup from "@Components/Form/RadioGroup";
import { AllowanceTypes, ComponentTypes, Effected, Platform, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import styles from "./index.module.scss";
export default (props) => {
    const [key, setKey] = useState(uuid());
    const [tariffTypes, setTariffTypes] = useState([]);
    const { data } = props;
    const edit = props.edit ? "SAVE" : "ADD";
    const save = () => {
        setKey(uuid());
        props.save();
    };
    const inputChange = (form) => {
        if (form[Effected.TariffComponentNetwork] !==
            data[Effected.TariffComponentNetwork]) {
            props.update({
                [Effected.TariffComponentNetwork]: form[Effected.TariffComponentNetwork],
                [Effected.TariffComponentType]: undefined,
            });
            return;
        }
        if (form[Effected.TariffComponentType] !==
            data[Effected.TariffComponentType]) {
            const title = displayEnum(ComponentTypes, form[Effected.TariffComponentType]);
            let hideAllowances = false;
            let allowances = [];
            switch (form[Effected.TariffComponentType]) {
                case ComponentTypes["Leader inc. Shared Data"]:
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std Calls (mins)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std SMS"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                    ];
                // break omitted
                case ComponentTypes.Shared_Data:
                    hideAllowances = true;
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["UK Data (GB)"],
                            [Effected.TariffComponentAllowanceQty]: 1,
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Shared,
                        },
                        ...allowances,
                    ];
                    break;
                case ComponentTypes.Data_Sharers:
                case ComponentTypes.Leader:
                    hideAllowances = true;
                    break;
                case ComponentTypes["Unlimited Voice & Text"]:
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std Calls (mins)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std SMS"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                    ];
                    break;
                case ComponentTypes["Sim Only Voice & Data"]:
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std Calls (mins)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["Std SMS"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Unlimited,
                        },
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["UK Data (GB)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Individual,
                        },
                    ];
                    break;
                case ComponentTypes.MBB:
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["UK Data (GB)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Individual,
                        },
                    ];
                    break;
                case ComponentTypes.Aggregated_Data:
                    allowances = [
                        {
                            [Effected.TariffComponentAllowanceType]: AllowanceTypes["UK Data (GB)"],
                            [Effected.TariffComponentAllowanceQtyType]: QTYType.Aggregated,
                        },
                    ];
                    break;
                case ComponentTypes.Network_ATC:
                    hideAllowances = true;
            }
            props.update({
                [Effected.TariffComponentType]: form[Effected.TariffComponentType],
                [Effected.TariffComponentNetwork]: form[Effected.TariffComponentNetwork],
                [Effected.TariffComponentTitle]: title,
                [Effected.TariffComponentItems]: allowances,
                [Effected.TariffComponentAllowanceHide]: hideAllowances,
            });
            return;
        }
        const update = { ...form };
        let numberLines = form[Effected.TariffComponentNumberLines] || 1;
        let standardCost = form[Effected.TariffComponentStandardCost] || 0;
        let discount = form[Effected.TariffComponentDiscount] || 0;
        let netCost = form[Effected.TariffComponentNetCost] || 0;
        let totalCost = form[Effected.TariffComponentTotalCost] || 0;
        if (form[Effected.TariffComponentType] ===
            ComponentTypes["Leader inc. Shared Data"]) {
            numberLines = 1;
        }
        switch (true) {
            case standardCost !== data[Effected.TariffComponentStandardCost]:
                netCost = Math.max(standardCost - discount, 0);
                discount = standardCost - netCost;
                break;
            case discount !== data[Effected.TariffComponentDiscount]:
                if (typeof discount === "string" &&
                    (discount === "" || discount.endsWith("."))) {
                    break;
                }
                discount = Math.max(form[Effected.TariffComponentDiscount], 0);
                netCost = Math.max(standardCost - discount, 0);
                standardCost = netCost + discount;
                break;
            case netCost !== data[Effected.TariffComponentNetCost]:
                discount = Math.max(standardCost - netCost, 0);
                standardCost = netCost + discount;
                break;
            case totalCost !== data[Effected.TariffComponentTotalCost]:
                netCost = totalCost / numberLines;
                discount = Math.max(standardCost - netCost, 0);
                standardCost = netCost + discount;
                break;
        }
        totalCost = netCost * numberLines;
        const items = form[Effected.TariffComponentItems] || [];
        props.update({
            ...data,
            ...update,
            [Effected.TariffComponentNumberLines]: form[Effected.TariffComponentNumberLines] || 1,
            [Effected.TariffComponentStandardCost]: standardCost,
            [Effected.TariffComponentDiscount]: discount,
            [Effected.TariffComponentNetCost]: netCost,
            [Effected.TariffComponentTotalCost]: totalCost,
            [Effected.TariffComponentItems]: [...items],
        });
    };
    useEffect(() => {
        setTariffTypes(props.filterComponentTypes(data[Effected.TariffComponentNetwork]));
    }, [
        data[Effected.TariffComponentNetwork],
        data[Effected.TariffComponentType],
    ]);
    const qtyType = data[Effected.TariffComponentType] === ComponentTypes.Shared_Data ||
        data[Effected.TariffComponentType] ===
            ComponentTypes["Leader inc. Shared Data"]
        ? "Number of GB"
        : data[Effected.TariffComponentType] === ComponentTypes.Data_Sharers
            ? "Number of Data Sharers"
            : "Number of Lines";
    const inputs = (update, data = [], errors = [], index) => {
        const values = data[index] || {};
        const error = errors[index] || {};
        const inputChange = (name, value) => {
            update({ name, value });
        };
        const dropdownChange = ({ option: { value }, field, }) => {
            update({ value, name: field });
        };
        const allowanceTypeOptions = Object.keys(AllowanceTypes)
            .map((key) => ({
            value: key,
            label: key,
        }))
            .filter(({ value }) => {
            const sharesValue = data.find((object = {}) => object[Effected.TariffComponentAllowanceType] === value);
            if (sharesValue === undefined) {
                return true;
            }
            return values[Effected.TariffComponentAllowanceType] === value;
        });
        const getQTYTypeOptions = () => {
            const options = [
                {
                    value: QTYType.Unlimited,
                    label: "Unlimited",
                },
                {
                    value: QTYType.Shared,
                    label: "Shared",
                },
                {
                    value: QTYType.Pooled,
                    label: "Pooled",
                },
                {
                    value: QTYType.Individual,
                    label: "Individual",
                },
            ];
            const possibleAggregateAllowanceTypes = [
                AllowanceTypes["UK Data (GB)"],
            ];
            if (possibleAggregateAllowanceTypes.includes(values[Effected.TariffComponentAllowanceType])) {
                options.push({
                    value: QTYType.Aggregated,
                    label: "Aggregated",
                });
            }
            return options;
        };
        const components = [
            React.createElement("div", { className: styles.subRow },
                React.createElement("div", { className: styles.subColumn },
                    React.createElement("div", { className: styles.upperInputs },
                        React.createElement("div", { className: styles.item },
                            React.createElement(Dropdown, { title: "Type", options: allowanceTypeOptions, onChange: dropdownChange, name: Effected.TariffComponentAllowanceType, value: values[Effected.TariffComponentAllowanceType], error: error[Effected.TariffComponentAllowanceType] })),
                        values[Effected.TariffComponentAllowanceQtyType] !==
                            QTYType.Unlimited && (React.createElement("div", { className: styles.item },
                            React.createElement(FormInput, { title: "QTY", type: "number", valueChanged: inputChange, name: Effected.TariffComponentAllowanceQty, value: values[Effected.TariffComponentAllowanceQty], error: error[Effected.TariffComponentAllowanceQty] }))))),
                React.createElement("div", { className: styles.subColumn },
                    React.createElement("div", { className: styles.centred },
                        React.createElement(RadioGroup, { className: styles.radios, options: getQTYTypeOptions(), name: Effected.TariffComponentAllowanceQtyType, value: values[Effected.TariffComponentAllowanceQtyType], valueChange: inputChange, error: error[Effected.TariffComponentAllowanceQtyType] })))),
        ];
        return components;
    };
    let formInputFields = [
        {
            label: "Allowances",
            name: Effected.TariffComponentItems,
            type: InputType.RepeatGroup,
            hidden: data[Effected.TariffComponentAllowanceHide],
            longerInput: true,
            groupInputs: inputs,
            defaultObj: {},
        },
        {
            label: qtyType,
            name: Effected.TariffComponentNumberLines,
            type: InputType.PlusMinus,
            noDecimal: true,
        },
        {
            label: (React.createElement(React.Fragment, null,
                "Standard Cost",
                data[Effected.TariffComponentType] !==
                    ComponentTypes["Leader inc. Shared Data"] && (React.createElement(React.Fragment, null,
                    " ",
                    "Per",
                    " ",
                    data[Effected.TariffComponentType] ===
                        ComponentTypes.Shared_Data ? (React.createElement(React.Fragment, null, "GB")) : (React.createElement(React.Fragment, null, "Line")))))),
            name: Effected.TariffComponentStandardCost,
            type: InputType.PlusMinus,
        },
        {
            label: (React.createElement(React.Fragment, null,
                data[Effected.TariffComponentType] ===
                    ComponentTypes.Shared_Data ||
                    data[Effected.TariffComponentType] ===
                        ComponentTypes["Leader inc. Shared Data"] ? (React.createElement(React.Fragment, null)) : (React.createElement(React.Fragment, null, "Per Line")),
                " ",
                "Discount")),
            name: Effected.TariffComponentDiscount,
            type: InputType.PlusMinus,
        },
        {
            label: "Net Cost",
            name: Effected.TariffComponentNetCost,
            type: InputType.PlusMinus,
        },
        {
            label: "Total Cost",
            name: Effected.TariffComponentTotalCost,
            type: InputType.PlusMinus,
        },
    ];
    const networkATCComponent = data[Effected.TariffComponentType] === ComponentTypes.Network_ATC;
    if (networkATCComponent) {
        formInputFields = [
            {
                label: "Total discount",
                name: Effected.TariffComponentStandardCost,
                type: InputType.PlusMinus,
            },
        ];
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: styles.wrapper },
            React.createElement(Form, { key: key, className: styles.form, buttons: false, data: data, errors: props.errors, change: inputChange, labelLeft: true, input: [
                    {
                        label: "Network",
                        name: Effected.TariffComponentNetwork,
                        type: InputType.DropdownEnum,
                        options: Platform,
                        placeholder: "Pick a network...",
                        filterOptions: props.filterNetworks,
                        multi: false,
                    },
                    {
                        label: "Product",
                        name: Effected.TariffComponentType,
                        type: InputType.DropdownOptions,
                        placeholder: "Pick a product...",
                        options: tariffTypes,
                    },
                    ...formInputFields,
                ] })),
        React.createElement(Button, { cta: true, click: save, className: styles.save }, edit)));
};
